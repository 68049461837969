
$boardWidth: 890px;
$adWidth: 300px;

// 공통

.boardContainer {
    display: flex;
    justify-content: space-between;
}

.funcBtn {
    font-size: 14px;
    font-weight: 600;
    width: 50px;
    height: 32px;
    border-radius: 3px;
    border: solid 1px #454545;
    background-color: #454545;
    color: #fff;
    cursor: pointer;
}

#funcBtnWrap {
    .funcBtn {
        &#writeBtn,
        &#writePostBtn,
        &#modifyPostBtn {
            border: solid 1px #012461;
            background-color: #012461;
            float: right;
        }

        &#writeBtn {
            width: 70px;
        }
    }

    #inputSearchPost {
        vertical-align: middle;
        height: 30px;
        width: 180px;
        margin-right: 7px;
        border: none;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 15px;
        border-radius: 5px;
    }
}

#adWrap {
    width: $adWidth;
    background-color: #e2e1e6;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    margin-top: 40px;
}

.ql-editor {
    min-height: 300px !important;
}

// Board Layout

#boardWrap {
    width: $boardWidth;

    #navWrap {
        .navBtn {
            font-size: 16px;
            padding: 10px 35px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            border-top: solid 1px #e2e1e6;
            border-left: solid 1px #e2e1e6;
            border-right: solid 1px #e2e1e6;
            border-bottom: none;
            background-color: #fff;
            color: #676767;
            font-weight: 500;
            cursor: pointer;

            &.active {
                background-color: #e2e1e6;
                color: #000b72;
                font-weight: 600;
            }
        }
    }

    #boardBodyWrap {
        background-color: #e2e1e6;
        padding: 15px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        #postListWrap {
            font-size: 13px;
            margin-top: 15px;

            table {
                width: 100%;
                text-align: center;
                border-collapse: collapse;

                thead {
                    tr {
                        border-top: solid 1px #bbb;
                        border-bottom: solid 1px #bbb;

                        th {
                            padding: 10px 0;
                            font-weight: 500;
                            background: linear-gradient(to bottom, #ddd, #fff)
                        }
                    }
                }

                tbody {
                    tr {
                        border-bottom: solid 1px #e1e2e6;

                        &:hover {
                            td {
                                background-color: #f3f4f7;

                                span {
                                    &:hover {
                                        color: #00198a !important;
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }

                        td {
                            padding: 5px 0;
                            line-height: 26px;
                            background-color: #fff;

                            &.tdTitle {
                                text-align: left;
                            }

                            .postKeyword,
                            .postTitle {
                                cursor: pointer;
                            }

                            .postKeyword {
                                color: #056fff;
                                margin: 0 5px;
                            }

                            .postNickName {
                                display: flex;
                                justify-content: left;
                                align-items: center;
                                gap: 5px;
                                white-space: nowrap;

                                img {
                                    width: 20px;
                                    height: 20px;
                                    margin-left: 20px;
                                    margin-right: 3px;

                                    &.teamIcon {
                                        width: 27px;
                                        margin-left: 16px;
                                        margin-right: 0;
                                    }
                                }
                            }

                            .postRecommendCnt {
                                color: #ff5e00;
                            }

                            .simpleReplyCnt {
                                color: #1c9795;
                                margin-left: 5px;
                            }
                        }
                    }
                }
            }
        }

        #paginationWrap {
            background-color: #fff;
            padding: 30px 10px;
            text-align: center;

            .pageFuncBtn {
                margin: 0 20px;
                color: #012461;
                cursor: pointer;
                border: none;
                background-color: transparent;
                font-weight: 600;

                &.prevBtn {
                    border-right: 1px solid #ccc;
                }

                &.nextBtn {
                    border-left: 1px solid #ccc;
                }

                &[disabled] {
                    color: transparent;
                    background-color: transparent;
                    cursor: default;
                    border: none;
                }
            }

            .pageNum {
                font-size: 16px;
                margin: 0 5px;
                border: none;
                background-color: transparent;
                color: #777;
                cursor: pointer;

                &.active {
                    color: #215bfb;
                    background-color: #dfdfdf;
                    border-radius: 5px;
                    padding: 1px 5px;
                    font-weight: 600;
                }
            }
        }
    }
}

// Post View (글 상세)

#postViewWrap {
    display: grid;
    gap: 15px;
    width: $boardWidth;
    background-color: #e2e1e6;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    #postWrap {
        padding: 15px 15px 0 15px;

        #titleWrap {
            padding: 5px 0;
            background-color: #e9f1ff;
            border-top: solid 2px #203d97;
            border-bottom: solid 2px #ccc;
            font-size: 13px;
            font-weight: 500;
            text-align: center;
            display: flex;
            justify-content: space-between;
            align-items: center;

            #divTitle {
                font-size: 18px;
                margin-left: 15px;

                #spanKeyword {
                    color: #056fff;
                }

                #spanTitle {
                    margin-left: 10px;
                    color: #454545;
                }
            }

            #divPostInfo {
                color: #000;
                margin-right: 15px;

                span {
                    padding: 0 20px;
                }
            }
        }

        #contentWrap {
            background-color: #fff;
            overflow: auto;
            padding: 15px;
        }

        #recommendCntWrap {
            background-color: #fff;
            text-align: center;
            font-size: 16px;
            color: #ff5e00;
        }

        #recommendImgWrap {
            background-color: #fff;
            text-align: center;

            #recommendBtn {
                width: 40px;
                margin: 10px 0;
                cursor: pointer;
            }
        }

        #postFuncWrap {
            margin-bottom: 15px;

            .funcBtn {
                &#modifyOnBtn,
                &#delPostBtn {
                    border: solid 1px #012461;
                    background-color: #012461;
                    float: right;
                }

                &#modifyOnBtn {
                    margin-left: 10px;
                }
            }
        }
    }

    #replyWrap {
        display: grid;
        gap: 15px;
        padding: 0 15px;

        #replyContentWrap {
            background-color: #fff;

            .divReplyRow {
                border-bottom: solid 1px #e1e2e6;
                padding: 7px;
                display: flex;
                position: relative;

                &:last-child {
                    border-bottom: none;
                }

                .left {
                    img {
                        width: 32px;
                        margin-top: 3px;
                        margin-left: 7px;
                        margin-right: 10px;

                        &.teamIcon {
                            width: 45px;
                            margin-left: 0;
                            margin-right: 4px;
                        }
                    }
                }

                .right {
                    .replyWriter {
                        font-size: 12px;
                        line-height: 14px;
                        font-weight: 500;
                        color: #000;
                    }

                    .replyContent {
                        font-size: 14px;
                        line-height: 14px;
                        margin: 10px 0;
                    }

                    .replyInfo {
                        font-size: 10px;
                        line-height: 10px;
                        color: #aaa;
                    }

                    .funcBtn {
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        border: solid 1px #c07300;
                        background-color: #ffffff;
                        color: #c07300;
                    }
                }
            }
        }

        #replyWriteWrap {
            display: flex;
            align-items: center;

            textarea {
                font-size: 15px;
                height: 80px;
                width: 748px;
                border: solid 1px #777;
                padding: 10px;
                margin-right: 10px;
                resize: none;
            }

            .funcBtn {
                &#writeReplyBtn {
                    width: 80px;
                    height: 99px;
                }
            }
        }
    }

    #funcBtnWrap {
        padding: 0 15px 15px 15px;
    }
}

// Post Write (글 쓰기)

#postWriteWrap {
    width: $boardWidth - 30px;
    background-color: #e2e1e6;
    padding: 15px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    #postWrap {
        background-color: #fff;
        padding: 15px;
        margin-bottom: 10px;

        .top {
            display: flex;
            justify-content: space-between;
            gap: 10px;
            margin-bottom: 10px;

            select {
                font-size: 15px;
                height: 36px;
            }

            input {
                font-size: 15px;
                height: 32px;
                width: 100%;
                border: solid 1px #e1e2e6;
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }
}

// Post Modify (글 수정)

#postModifyWrap {
    width: $boardWidth - 30px;
    background-color: #e2e1e6;
    padding: 15px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    #postWrap {
        background-color: #fff;
        padding: 15px;
        margin-bottom: 20px;

        .top {
            display: flex;
            justify-content: space-between;
            gap: 10px;
            margin-bottom: 10px;

            select {
                font-size: 15px;
                height: 36px;
            }

            input {
                font-size: 15px;
                height: 32px;
                width: 100%;
                border: solid 1px #e1e2e6;
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }
}