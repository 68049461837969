@import 'color.scss';

/* 상단 탭 컨테이너 start */
#upper_tab_container {
    // background-color: #e6e6e6;
    height: 335px;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
}

#sp_away, #sp_home  {
    width: 50%;
    border: 1px solid $hiball_border;

    .title {
        color: #555;
        font-size: 16px;
        font-weight: 400;
        margin: 15px 10px 0px 10px;
        line-height: 16px;
    }

    .right {
        text-align: right;
    }

    .body {
        margin-top: 15px;
        margin-left: 10px;
        margin-right: 10px;

        .top {
            display: flex;
        }
    }

    .backnum_wrap {
        position: relative;
        height:118px;

        img {
            // opacity: 0.5;
            width: 94px;
        }

        .backnum_text {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            text-align: center;
            color: white;
            font-size: 45px;
        }
    }

    .profile {
        margin: 0px 12px 0px 12px;
        min-width: 162px;
    }

    .profile_title {
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        text-align: center;
        border-bottom: 1px solid #ddd;
        padding-bottom: 12px;
    }

    .profile_content {
        padding-top: 12px;

        td {
            color: #555;
            font-size: 14px;
            font-weight: 400;
            line-height: 14px;

            &:first-child {
                color: #2683a5;
            }
        }
    }

    .record {
        table {
            border-collapse: collapse;
            text-align: center;
            width: 100%;
        }

        td {
            color: #555;
            font-size: 12px;
            font-weight: 400;
            border: 1px solid #ccc;
            padding: 3px 4px;

            &:first-child {
                background-color: #eee;
                width: 59px;
            }

            span {
                font-size: 14px;
                font-weight: 500;
                color: black;
            }
        }
    }

    .ball_table {
        width: 194px !important;
        border: 1px solid #ccc;

        th {
            color: black;
            font-size: 12px;
            font-weight: 400;
            background-color: #d1d9f1;
            padding: 3px;
        }

        td {
            background-color: white !important;
            padding: 3px;

            &:first-child {
                width: auto;
            }

            span {
                color: #20489d;
                font-size: 12px;
            }
        }
    }
}

#upper_tab_wrap {
    width: 50%;
    // border: 1px solid #e6e6e6;
    border: 1px solid $hiball_border;
    margin: 0px 10px;
}

#upper_tab_btn_area {
    height: 44px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
	column-gap: 1px;
    background-color: white;

    .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        // color: white;
        color: #000;
        // background-color: #bbb;
        border: 1px solid $hiball_border;
        font-size: 20px;
        font-weight: 500;

        &:hover {
            cursor: pointer;
        }
    }

    .active {
        background-color: #2a4bac;
        color: #fff;
    }
}

#upper_tab_content {
    display: grid;
    height: 291px;
}

#record_season_wrap, #record_match_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .bar_border {
        width: 247px;
        height: 25px;
        background-color: #fff;
        border-radius: 12.5px;
        margin-top: 15px;
    }

    .bar_graph {
        height: 25px;
        background-color: #555;
        border-radius: 12.5px;
    }

    .bar_border .active {
        background-color: #ce0d29;
    }
    
    .team_away {
        .bar_graph, .bar_value {
            float: right;
        }

        .bar_value {
            margin-right: 10px;
        }
    }

    .team_home {
        .bar_graph, .bar_value {
            float: left;
        }

        .bar_value {
            margin-left: 10px;
        }
    }

    .bar_value {
        font-size: 14px;
        font-weight: 400;
        color: white;
        line-height: 25px;
        font-style: italic;
    }

    .bar_title {
        font-size: 14px;
        font-weight: 400;
        line-height: 40px;
        text-align: center;
        margin-top: 15px;
        color: #666;
    }
}

.starter_record_table tr td {
    background-color: #fff;
}
/* 상단 탭 컨테이너 end */

#lower_tab_area {
    padding-bottom: 30px;
}

/* 하단 탭 컨테이너 start */
#lower_tab_btn_area {
    margin: 0 auto 0 auto;
    width: 50%;
    height: 44px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	column-gap: 1px;
    background-color: white;
}

#lower_tab_btn_area .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-top: solid 1px #e2e1e6;
    border-left: solid 1px #e2e1e6;
    border-right: solid 1px #e2e1e6;
    border-bottom: none;
    background-color: #fff;
    color: #676767;
}

#lower_tab_btn_area .active {
    // background-color: #e2e1e6;
    color: #000b72;
    border: 1px solid $hiball_border;
    border-width: 1px 1px 0px 1px;
    font-weight: 600;
}

#lower_tab_btn_area .btn:hover {
    cursor: pointer;
}

#lower_tab_content {
    margin: 0 auto 0 auto;
    width: 1170px;
    // background-color: #e2e1e6;
    border: 1px solid $hiball_border
    // border-radius: 10px;
}

#lower_tab1 {
    display: flex;

    .team_away, .team_home {
        width: 50%;
    }

    .team_home {
        // border-left: 1px solid #ccc;
        border-left: 1px solid $hiball_border;
    }

    .title_wrap {
        display: flex;
        justify-content: space-between;
    }

    .title {
        font-size: 18px;
        font-weight: 500;
        margin-top: 30px;
        margin-left: 20px;
    }

    .tab_wrap {
        display: flex;
        margin-top: 30px;
        margin-right: 20px;

        div {
            width: 50px;
            height: 25px;
            background-color: #eee;
            color: #666;
            font-size: 12px;
            font-weight: 400;
            border-radius: 12.5px;
            line-height: 25px;
            text-align: center;
            margin-left: 3px;

            &:hover {
                cursor: pointer;
            }
        }

        .active {
            background-color: #2a4bac;
            color: white;
        }
    }

    .body {
        margin-left: 20px;
    }

    table {
        width: calc(100% - 20px);
        margin-top: 15px;
    }

    .record_season_10games {
        .top {
            tr {
                background-color: white;
            }

            thead {
                tr {
                    &:first-child {
                        border-top: 3px solid #2a4bac;
                        background-color: #eee;
                        color: black;
                    }
                }
            }

            td {
                color: #2a4bac;

                &:first-child {
                    color: #2683a5;
                }
            }
        }

        .bottom {
            margin-bottom: 20px;
        }
    }

    .away_div {
        display: inline-block;
        width: 42px;
        height: 22px;
        background-color: black;
        color: white;
        border-radius: 11px;
        line-height: 22px;
    }

    .home_div {
        display: inline-block;
        width: 42px;
        height: 22px;
        background-color: #666;
        color: white;
        border-radius: 11px;
        line-height: 22px;
    }

    .win_div {
        display: inline-block;
        width: 23px;
        height: 22px;
        background-color: #ce0d29;
        color: white;
        border-radius: 11px;
        line-height: 22px;
    }

    .lose_div {
        display: inline-block;
        width: 23px;
        height: 22px;
        background-color: #2a4bac;
        color: white;
        border-radius: 11px;
        line-height: 22px;
    }
    
    .save_div {
        display: inline-block;
        width: 23px;
        height: 22px;
        background-color: #666;
        color: white;
        border-radius: 11px;
        line-height: 22px;
    }

    .hold_div {
        display: inline-block;
        width: 23px;
        height: 22px;
        background-color: #666;
        color: white;
        border-radius: 11px;
        line-height: 22px;
    }

    .none_div {
        display: inline-block;
        width: 23px;
        height: 22px;
        background-color: #000;
        color: white;
        border-radius: 11px;
        line-height: 22px;
    }
}

#lower_tab3 {
    display: flex;

    .team_away, .team_home {
        width: 50%;
        border-left: 1px solid #e9e9e9;
    }

    .head {
        margin: 20px 20px 0px 20px;

        .team_icon {
            width: 78px;
            height: 30px;
            font-size: 14px;
            font-weight: 400;
            border-radius: 15px;
            line-height: 30px;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;

            img { width: 18px; height: 18px; margin-right: 3px;}
        }

        .team_logo_wrap { display: flex; justify-content: center;}
        .team_logo { width: 112px; height: 82px;}
    }

    .body { margin: 20px;
        .no_lineup {
            height: 400px;
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .table_batter_title, .table_pitcher_title { font-size: 18px; font-weight: 500; }
    .table_pitcher_title { margin-top: 30px; }
    .table_batter, .table_pitcher { overflow: auto; margin-top: 15px; width: 100%; }
    .table_pitcher { margin-bottom: 20px; }
    .table_batter, .table_pitcher {
        th, td { white-space: nowrap; }
    }
}

#lower_tab4 {
    display: flex;

    .team_away, .team_home {
        width: 50%;
    }

    .team_home {
        border-left: 1px solid #e9e9e9;
    }

    .head {
        margin: 20px 20px 0px 20px;

        .team_icon {
            width: 78px;
            height: 30px;
            font-size: 14px;
            font-weight: 400;
            border-radius: 15px;
            line-height: 30px;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 18px;
                height: 18px;
                margin-right: 3px;
            }
        }

        .team_logo_wrap {
            display: flex;
            justify-content: center;
        }

        .team_logo {
            width: 112px;
            height: 82px;
        }
    }

    .body {
        padding-top: 70px;
        padding-bottom: 100px;
        margin-left: 20px;
        margin-right: 20px;
        position: relative;
    }

    .stadium_bg img {
        width: 550px;
    }

    .depth_list {
        width: 103px;
        height: 100px;
        border: 1px solid #a3b7f3;
        background-color: rgba(255, 255, 255, 0.5);
    }

    .depth_row1, .depth_row2, .depth_row3, .depth_row4 {
        display: flex;
        position: absolute;
    }

    .depth_row1 {
        top: 35px;
    }

    .depth_row2 {
        top: 155px;
    }

    .depth_row3 {
        top: 274px;
    }

    .depth_row4 {
        top: 450px;
    }

    .depth_list .position_name {
        text-align: center;
        font-size: 16px;
        font-weight: 500;
    }

    .depth_list .depth_content {
        padding: 10px;

        td {
            font-size: 13px;
            font-weight: 400;
            text-align: right;
            line-height: 14px;
            color: #666;
        }

        tr:first-child td {
            color: black;
        }

        tr td:first-child {
            text-align: left;
            width: 60px;
        }
    }

    .team_lf {margin-left: 40px;}
    .team_lf, .team_rf {margin-top: 35px;}
    .team_cf, .team_rf {margin-left: 79px;}
    .team_ss {margin-left: 150px;}
    .team_2b {margin-left: 43px;}
    .team_rp {height: 200px;}
    .team_sp {height: 137px;}
    .team_3b, .team_sp, .team_1b, .team_dh {margin-left: 7px;}
    .team_rp, .team_3b, .team_1b, .team_dh {margin-top: 75px;}
    .team_c {margin-left: 224px;}
}

#lower_tab5 {
    padding: 20px 10px;
}

#lower_tab5 .head {
    display: flex;
    justify-content: center;
    align-items: center;
}

#lower_tab5 .head .team_away, #lower_tab5 .head .team_home {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

#lower_tab5 .head .team_icon {
    width: 57px;
    height: 30px;
    position: absolute;
    display: flex;
    align-items: center;
}

#lower_tab5 .head .team_away .team_icon {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    justify-content: left;
    right: 90px;
}

#lower_tab5 .head .team_away .team_icon img {
    margin-left: 10px;
}

#lower_tab5 .head .team_home .team_icon {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    justify-content: right;
    left: 90px;
}

#lower_tab5 .head .team_home .team_icon img {
    margin-right: 10px;
}

#lower_tab5 .team_logo_wrap {
    z-index: 1;
}

#lower_tab5 .head .vs_text {
    font-size: 25px;
    font-weight: 700;
    margin: 20px;
}

#lower_tab5 .body .team_home {
    margin-top: 35px;
}

#lower_tab5 .matrix_content {
    overflow-x: auto;
}

#lower_tab5 .matrix_content table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
}

#lower_tab5 .matrix_content table .border_pitcher_team {
    height: 8px;
}

#lower_tab5 .matrix_content table .border_batter_team {
    width: 8px;
}

#lower_tab5 .matrix_content table .header_batter_team {
    width: 30px;
}

#lower_tab5 .matrix_content table .header_batter_team div {
    word-break: break-all;
    width: 15px;
    margin: auto;
}

#lower_tab5 .matrix_content th {
    height: 30px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
}

#lower_tab5 .matrix_content td {
    height: 60px;
    font-size: 14px;
    font-weight: 400;
    border-bottom: 1px solid #ddd;
    text-align: center;
    padding: 5px 0px;
    color: #666;
    line-height: 18px;
    background-color: #fff;
}

#lower_tab_content .hiball_table tr td {
    background-color: #fff;
}
/* 하단 탭 컨테이너 end */