@import 'color.scss';

/* 경기목록 start */
#schedule_container {
    // background-color: #ddd;
    height: 137px;
    // border-top-left-radius: 10px;
    // border-top-right-radius: 10px;
    margin-top: 10px;
}

#schedule_calendar {
    height: 100%;
    width: 75px;
    float: left;
    // padding-top: 1px;

    .body {
        background-color: white;
        color: #2184b2;
        // border: 1px solid #e6e6e6;
        border: 1px solid $hiball_border;
        border-width: 1px 0px 1px 1px;
        height: 135px;
        line-height: 87px;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        // border-top-left-radius: 10px;
        // border-top-right-radius: 10px;
        display: flex;
        align-items: center;
        flex-direction: column;

        .arrow {
            cursor: pointer;
        }

        span {
            cursor: pointer;
        }
    }

    .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #bbb;
        color: #3a3a3a;
        height: 36px;
        font-size: 14px;
        font-weight: 500;

        &:hover {
            cursor: pointer;
            background-color: rgba(0, 0, 0, 0.4);
        }

        span {
            padding: 0 5px;
        }
    }
}

#schedule_arrow_right,
#schedule_arrow_left {
    width: 3%;
    height: 100%;
    float: left;
    border: 1px solid #20489d;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

#schedule_game_container {
    
    
}

#schedule_game_list {
    // width: 93.2vw;
    // width: calc(100%-75px);
    height: 100%;
    position: relative;
    // display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    display: flex;
	// column-gap: 4px;
    height: 100%;
    // width: 100%;
    // width: 87.2%;
    // float: left;
    // float: left;
    overflow-x: scroll;
    border: 1px solid #20489d;
    box-sizing: border-box;

    &::-webkit-scrollbar {
        display:none
    }

    .item {
        padding: 4px;
        box-sizing: border-box;
        .body {
            background-color: white;
            height: 90px;
            border: 1px solid $hiball_border;
            border-width: 1px 1px 0px 1px;
            box-sizing: border-box;
            width: 230px;

            .top {
                position: relative;
                height: 34px;
                font-size: 14px;
                font-weight: 500;

                .circle_left {
                    position: absolute;
                    top: 8px;
                    left: 28%;
                    height: 23px;
                    width: 50px;
                    background-color: #58abcf;
                    color: white;
                    border-radius: 12px;
                    line-height: 23px;
                    text-align: center;
                }

                .circle_right {
                    position: absolute;
                    top: 8px;
                    left: 48%;
                    height: 21px;
                    width: 50px;
                    background-color: white;
                    color: #58abcf;
                    border: 1px solid #58abcf;
                    border-radius: 11px;
                    line-height: 21px;
                    text-align: center;
                }
            }

            .bottom {
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                height: 52px;
                font-size: 14px;
                text-align: center;

                .team_away,
                .team_home {
                    width: 65px;
                    margin-bottom: 10px;

                    img {
                        width: 45px;
                    }
                }

                .game_status {
                    font-size: 20px;
                    font-weight: 700;
                }

                .vs_score {
                    font-size: 22px;
                    font-weight: 700;
                    width: 70px;
                }

                .double_header {
                    font-size: 20px;
                    font-weight: 400;
                    width: 70px;
                }

                .game_cancel {
                    font-size: 15px;
                    font-weight: 700;
                    width: 70px;
                }
            }
        }

        .triple_btn {
            background-color: #C4C4C4;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            column-gap: 1px;
            height: 36px;
            font-size: 14px;
            font-weight: 500;
            border: 1px solid #20489d;
            border-width: 0px 1px 1px 1px;

            span {
                padding: 0 5px;
            }

            .summary,
            .detail {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #D5D5D5;
                color: #012461;

                &:hover {
                    cursor: pointer;
                    background-color: rgba(196, 196, 196, 0.674);
                }
            }
        }

        .btn {
            background-color: #C4C4C4;
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 1px;
            height: 36px;
            font-size: 14px;
            font-weight: 500;
            border: 1px solid #20489d;
            border-width: 0px 1px 1px 1px;

            span {
                padding: 0 5px;
            }

            .summary,
            .detail {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #D5D5D5;
                color: #012461;

                &:hover {
                    cursor: pointer;
                    background-color: rgba(196, 196, 196, 0.674);
                }
            }
        }

        .full_btn {
            background-color: #C4C4C4;
            display: grid;
            grid-template-columns: 1fr;
            column-gap: 1px;
            height: 36px;
            font-size: 14px;
            font-weight: 500;
            border: 1px solid #20489d;
            border-width: 0px 1px 1px 1px;

            span {
                padding: 0 5px;
            }

            .summary,
            .detail {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #D5D5D5;
                color: #012461;

                &:hover {
                    cursor: pointer;
                    background-color: rgba(196, 196, 196, 0.674);
                }
            }
        }

        .arrow {
            text-align: center;
            display: none;
        }
    }

    .active {
        .body {
            background-color: #20489d;
            color: white;
        }

        .btn {
            background-color: #2184b2;

            .summary,
            .detail {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #012461;

                &:hover {
                    cursor: pointer;
                    background-color: rgba(210, 210, 210, 0.294);
                }
            }

            .summary {
                color: #27b3f7;
            }

            .detail {
                color: white;
            }
        }

        .triple_btn {
            background-color: #2184b2;

            .summary,
            .detail {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #012461;

                &:hover {
                    cursor: pointer;
                    background-color: rgba(210, 210, 210, 0.294);
                }
            }

            .summary {
                color: #27b3f7;
            }

            .detail {
                color: white;
            }
        }

        .full_btn {
            background-color: #2184b2;

            .summary,
            .detail {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #012461;

                &:hover {
                    cursor: pointer;
                    background-color: rgba(210, 210, 210, 0.294);
                }
            }

            .summary {
                color: #27b3f7;
            }

            .detail {
                color: white;
            }
        }

        .arrow {
            display: block;
        }
    }
}
/* 경기목록 end */

/* 시즌성적&상대전적 start */
#record_container {
    display: flex;
    // grid-template-columns: 1fr 1fr;
	// column-gap: 10px;
    background-color: white;
    margin-top: 20px;

    #record_season,
    #record_match {
        font-size: 22px;
        font-weight: 300;
        padding: 5px 15px 15px 15px;
        border: 1px solid $hiball_border;
        // background-color: #e6e6e6;
        // border-radius: 10px;

        .body {
            .top {
                display: flex;

                .title {
                    // color: #18348b;
                    width: 20%;
                    color: #000;
                    font-size: 22px;
                    font-weight: 400;
                    margin-bottom: 5px;
                    text-align: center;
                }

                .team_pitcher {
                    flex-direction: column;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40%;
                    padding: 5px 0;

                    .pitcher_name {
                        font-size: 1em;
                        display: flex;

                        img {
                            width: 45px;
                        }
                    }

                    .pitcher_stat {
                        color: $hiball_border;
                    }
                }

                .away {
                    align-items: flex-start;
                }

                .home {
                    align-items: flex-end;
                }

                .team_title {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 20%;
                    padding: 5px 0;
                }

                .team_away,
                .team_home {
                    // background-color: #8397c1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40%;
                    padding: 5px 0;

                    .team_name {
                        color: #000;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-weight: 400;

                        img {
                            width: 45px;
                        }
                    }

                    .team_score {
                        color: #20489d;

                        span {
                            font-weight: 500;
                        }
                    }
                }

                // .team_away {
                //     // border-top-left-radius: 22px;
                //     // border-bottom-left-radius: 22px;
                // }

                // .team_home {
                //     // border-top-right-radius: 22px;
                //     // border-bottom-right-radius: 22px;
                //     box-shadow: -2px 0 1px -1px #ccc;
                // }
            }

            .bottom {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                text-align: center;

                .team_pitcher {
                    font-size:14px;
                    width: 100%;
                }

                .away {
                    padding: 0px 5px 0px 0px;
                }

                .home {
                    padding: 0px 0px 0px 5px;
                }

                .record_table {
                    width: 100%;
                    border-collapse: collapse;
                    table-layout: fixed;

                    td {
                        border: 1px solid black;
                    }
                }
            }
        }
    }
}
/* 시즌성적&상대전적 end */

// 팀 로스터 뎁스 start

#team_roster_container {
    table.main_lineup_table {
        width: 100%;
        font-size: 14px;

        border-collapse: collapse;
        thead {
            th {
                height:20px;
            }

            th:nth-child(2) {
                border-right: 1px solid black;
                border-left: 1px solid black;
            }
        }

        tbody {

            tr:nth-child(odd) {
                background-color: #f0f0f0;
            }

            td {
                // border: 1px solid black;
                text-align: center;
            }

            td:nth-child(2) {
                width: 33%;
            }

            td:nth-child(3) {
                border-right: 1px solid black;
                border-left: 1px solid black;
            }

            td:nth-child(5) {
                width: 33%;
            }
        }
    }
}

.team_roster_depth {
    // border-radius: 10px;
    // background-color: #e2e1e6;
    height: 700px;
    // padding: 8px;
    // margin-bottom: 20px;
    // margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;

    .team_depth_row {
        display: flex;
        justify-content: space-between;
        gap: 10px;

        .depth_wrap {
            width: 32.8%;
            background-color: #fff;
            // border-radius: 10px;
            border: 1px solid $hiball_border;
            height: 345px;
        }
    }

    .home_small_depth {
        display: flex;
        justify-content: center;
        margin-top: 25px;

        .depth_body {
            margin: 0px 5px;
            position: relative;
        }

        .stadium_bg img {
            width: 330px;
        }

        .depth_list {
            width: 100px;
            height: 30px;
            border: 1px solid #a3b7f3;
            background-color: rgba(255, 255, 255, 0.5);
        }

        .depth_row1, .depth_row2,
        .depth_row3, .depth_row4 {
            display: flex;
            position: absolute;
            margin-top: 25px;
        }
        
        .depth_row1 {
            top: 0px;
        }
        
        .depth_row2 {
            top: 80px;
        }
        
        .depth_row3 {
            top: 125px;
        }
        
        .depth_row4 {
            top: 180px;
        }

        .home_depth_list {
            width: 100px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
    
            .home_content {
                padding: 5px 3px;
                font-size:13px;
                font-weight: 400;
                border: 1px solid #a3b7f3;
                background-color: rgba(255, 255, 255, 0.5);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
    }

    // .team_lf, .team_rf {margin-top: 35px;}
    .team_lf, .team_rf, .team_cf {margin-left: 8px;}
    .team_ss {margin-left: 38px;}
    .team_2b {margin-left: 54px;}

    .team_3b, .team_sp, .team_1b { margin-left: 8px;}

    .team_c, .team_dh {margin-left: 116px;}
    // .team_c { margin-top: 20px;}
    .team_dh { 
        margin-left: 8px; 
        // height: 48px;
    }    
    .team_rp { height: 68px; margin-left: 6px;}
}

// 팀 로스터 뎁스 end

/* 랭킹 start */
#ranking_container {
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr;
	// column-gap: 10px;
    // height: 515px;
    // margin-top: 20px;
    margin-bottom: 25px;
}

.ranking_box {
    height: 100%;
    // background-color: #e2e1e6;
    padding: 0 15px;
    // border-radius: 10px;
    border: 1px solid $hiball_border;

    .title {
        color: #18348b;
        font-size: 30px;
        font-weight: 300;
        margin-top: 20px;
        text-align: center;

        span {
            color: #2683a5;
            font-weight: 500;
        }
    }

    .table {
        margin-top: 25px;

        .name_wrap {
            height: 26px;

            .name {
                float: left;
                font-size: 18px;
                font-weight: 400;

                span {
                    // color: #2683a5;
                    color: #000;
                }
            }

            .more {
                float: right;
                font-size: 12px;
                background-color: #58abcf;
                color: white;
                height: 24px;
                line-height: 24px;
                width: 64px;
                border-radius: 12px;
                text-align: center;
                text-decoration-line: none;

                &:hover {
                    cursor: pointer;
                    background-color: #d33636;
                }
            }
        }

        .content {
            margin-top: 15px;

            table {
                border-collapse: collapse;
                width: 100%;
                font-size: 14px;
                color: #666;
                text-align: center;

                tr {
                    &:hover {
                        background-color: #dbe4fc;
                    }
                }

                th {
                    height: 32px;
                    background-color: #1c2f66;
                    color: white;
                }

                td {
                    height: 32px;

                    img {
                        height: 24px;
                        width: 36px;
                    }

                    // background-color: white;
                }
            }
        }
    }
}

#ranking_team {
    .table {
        .content {
            table {
                tr {
                    border-bottom: 1px solid #ddd;

                    td {
                        &:nth-child(3) {
                            color: $link_word;
                        }
                    }
                }
            }
        }
    }
}

#ranking_hitter,
#ranking_pitcher {
    .title {
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        .arrow_prev,
        .arrow_next {
            cursor: pointer;
        }
    }

    .table {
        .content {
            table {
                tr {
                    border-bottom: 1px solid #ddd;

                    td {
                        &:nth-child(2) {
                            color: black;
                        }

                        &:nth-child(3) {
                            color: $link_word;
                        }
                    }
                }
            }
        }
    }
}
/* 랭킹 end */

/* 퀵메뉴 start */
#quick_menu {
    background-image: url("../image/main/bg_quick.png");
    height: 162px;
    margin-top: 30px;
}

#quick_menu_item_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    .item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 392px;
        height: 125px;
        border: 1px solid #4054cb;

        &:hover {
            cursor: pointer;
            background-color: rgba(91, 193, 211, 0.294);
        }

        .icon_wrap {
            width: 30%;
            float: left;

            img {
                max-width: 100%;
            }
        }

        .comment {
            width: 51%;
            float: left;
            color: white;
            font-size: 16px;

            .title {
                color: #76bfd3;
                font-size: 24px;
                font-weight: 500;
            }
        }
    }
}
/* 퀵메뉴 end */

/* 용어사전 start */
#glossary_container {
    margin-top: 40px;
    text-align: center;
}

#glossary_title {
    color: #3d3d3d;
    font-size: 30px;
    font-weight: 300;

    span {
        color: #2683a5;
        font-weight: 500;
    }
}

#glossary_grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 40px;
	column-gap: 40px;
    text-align: left;
    margin-top: 40px;

    .item {
        padding: 10px;

        .comment {
            color: #777;
            font-size: 16px;

            .title {
                color: #111;
                font-size: 18px;
            }
        }
    }
}
/* 용어사전 end */


.search_container { padding-top: 6px; padding-left: 128px; }

.search_container .search_input { border: 0; width: 110px; background-color: #fff; font-size: 12px; }

.search_input:focus { outline: none; }

.search_container .search_form { border: 1px solid #ddd; border-radius: 2px; width: 164px; padding-left: 6px; -webkit-box-sizing: border-box; box-sizing: border-box; background-color: #fff; }

.search_player_list_container { top: 50px; position: absolute; z-index: 11; width: 225px; background-color: white; display: none; border: 1px solid #8e8f91; -webkit-box-sizing: border-box; box-sizing: border-box; }

.search_list_area { max-height: 300px; overflow-y: auto; }

.search_player_list_container:hover { cursor: pointer; }

.search_player_list_container .search_player_list_conatiner_title { background-color: #dbdbdb; font-size: 12px; font-weight: 1000; color: #666; padding: 5px 10px; }

.search_player_list_container .search_player_item { 
    display: flex;
    position: relative;
    padding: 5px 10px;
    font-weight: 700;
    border-bottom: 1px solid #ddd; 
    height: 70px;
    line-height: normal;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
        display: flex;
        align-items: center;
        justify-items: center;
    }
}

.search_player_list_container .search_player_item.last { border-bottom: none; }