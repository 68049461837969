.hiball_table {
    border-collapse: collapse;
    text-align: center;
    width: 100%;

    thead {
        tr {
            &:first-child {
                background-color: #2a4bac;
                color: white;
            }
        }
    }

    tr {
        background-color: #fff;
        border-bottom: 1px solid #ddd;

        &:hover {
            background-color: #f0f2ff;
        }
    }

    th {
        font-size: 12px;
        font-weight: 500;
        padding: 7px;
    }

    td {
        color: #666;
        font-size: 12px;
        font-weight: 500;
        padding: 7px;
        height:14px;

        &.first {
            color: #2683a5;
        }

        &.link {
            color: #1b4397;
            cursor: pointer;
        }
    }
}

.record_table {
    td {
        &.text {
            text-align: center;
        }
    }
}