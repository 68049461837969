#weekly_main_container {
    .title {
        font-size: 18px;
        padding: 5px;
    }

    .best_moment {
        padding: 5px 15px;
        font-size: 14px;
    }

    .half_div {
        width: 50%;
    }

    .three_div {
        width: 33.3%;
    }

    .content_table {
        padding: 5px;

        .red_color {
            color: red;
        }

        .blue_color {
            color: blue;
        }
    }

    .stat_comment {
        font-size: 14px;
        color: #707070;
    }

    .trackmanArea {
        padding: 5px;
    }

    .record_div {
        border: 1px solid black;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 3px;
    }

    .trackmanChart {
        display: flex;
        justify-content: space-evenly;
    }

    #weekly_depth {
        display: flex;
        justify-content: center;
    
        .body {
            margin: 0px 5px;
            position: relative;
        }
    
        .stadium_bg img {
            width: 550px;
        }
    
        .depth_list {
            width: 100px;
            height: 100%;
            border: 1px solid #a3b7f3;
            background-color: rgba(255, 255, 255, 0.5);
        }
    
        .depth_row1, .depth_row2,
        .depth_row3, .depth_row4 {
            display: flex;
            position: absolute;
            // margin-top: 25px;
        }
        
        .depth_row1 {
            top: 20px;
        }
        
        .depth_row2 {
            top: 140px;
        }
        
        .depth_row3 {
            top: 240px;
        }
        
        .depth_row4 {
            top: 350px;
            left: 216px;
        }
    
        .depth_list {
            .position_name {
                text-align: center;
                font-size: 14px;
                font-weight: 500;
            }
    
            .depth_content {
                padding: 6px;
    
                td {
                    font-size: 13px;
                    font-weight: 400;
                    text-align: right;
                    line-height: 14px;
                    color: #666;
                }
    
                tr:first-child td {
                    color: black;
                }
    
                tr td:first-child {
                    text-align: left;
                    width: 60px;
                }
            }
        }
    
        .team_lf {margin-left: 6px;}
        .team_rf, .team_cf { margin-left: 114px;}
        .team_lf, .team_rf {margin-top: 35px;}
        .team_ss {margin-left: 114px;}
        .team_2b {margin-left: 114px;}
    
        .team_3b, .team_sp, .team_1b { margin-left: 6px;}
    
        .team_c, .team_dh {margin-left: 6px;}
        .team_c { margin-top: 20px;}
        .team_dh { 
            // height: 48px;
            margin-left: 6px; 
        }
        .team_rp { 
            // height: 68px; 
            margin-left: 6px;
        }
    }

    #relief_container {
        display: flex;

        .relief_div, .total_div, .name_div {
            border: 1px solid black;

            div {
                display: flex;
                height: 50px;
                align-items: center;
                justify-content: center;
            }
        }

        .relief_div {
            width: 10%;
        }

        .total_div, .name_div {
            width: 15%;
        }
    }
}