#result_container {
    background-color: #e2e1e6;
    border-radius: 10px;
    padding: 8px;
    
    .inner_container {
        display: flex;
        height: 362px;
        justify-content: space-around;
    }
}

/* 하단 탭 컨테이너 start */

#result_wrapper {
    width: 60%;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
}

#winrate_wrapper {
    width: 40%;
    background-color: #fff;
    border-radius: 10px;
    margin-left: 10px;
}

#score_board_title {
    padding-top: 20px; padding-bottom:15px;
    text-align: center;
    color: #0a3b71;
    font-size: 18px;
    font-weight: 500;
}

#score_board_table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    table-layout: fixed;

    thead {
        tr {
            border: 1px solid #0a3b71;
        }
    }

    tr {
        border: 1px solid #ccc;

        th {
            background-color: #0a3b71;
            color: #ffffff;
            height: 30px;
            font-size: 14px;
            font-weight: 600;

            &:first-child {
                width: 62px;
            }
        }

        td {
            height: 30px;
            font-weight: 500;
            font-size: 14px;

            &:first-child {
                background-color: #ececec;
                font-weight: 700;
            }
        }
    }
}

#game_result_container {
    margin-top: 8px;
}

#game_result_table {
    width: 100%;
    text-align: center;
    table-layout: fixed;
    border-collapse: collapse;

    tr {
        border: 1px solid #ccc;

        th {
            background-color: #ececec;
            height: 30px;
            font-size:14px;
            font-weight: 700;

            &:nth-child(even) {
                background-color: #D8DCE1;
                color: #000;
            }
        }

        td {
            height: 30px;
            font-size:14px;
            font-weight: 500;

            &:nth-child(even) {
                background-color: #E8ECF1;
                color: #000;
            }
        }
    }
}

#player_result_container { 
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    margin-top:10px;
}

#player_result_table {
    width: 100%;
    height: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    text-align: left;

    .hr_batter {
        font-size: 16px;
    }

    tr {
        th {
            width: 8%;
            height: 50%;
            font-size:16px;
            font-weight: 400;
        }

        td {
            height: 30px;
            font-size:16px;
            word-break: keep-all;
            word-wrap: break-word;

            .table_box {
                height: 114px;
                overflow-y: auto;
                display: flex;
                flex-direction: column;
                justify-content: center;

                &::-webkit-scrollbar {
                    width: 12px;  /* 스크롤바의 너비 */
                }

                &::-webkit-scrollbar-thumb {
                    background: #3A5E9F; /* 스크롤바의 색상 */
                    border-radius: 6px;
                    border: 3px solid #1A3B6D;
                }

                &::-webkit-scrollbar-track {
                    background-color: rgba(0,0,0,0);
                }
            }

            .active {
                color: #ce0d29;
            }
        }
    }
}

/* 탭 컨테이너 start */
#result_detail_wrap {
    margin-top:30px;
    margin-bottom: 20px;
}

#result_btn_area {
    margin: 0 auto 0 auto;
    width: 50%;
    height: 44px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
	column-gap: 1px;
    background-color: white;

    .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 500;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-top: solid 1px #e2e1e6;
        border-left: solid 1px #e2e1e6;
        border-right: solid 1px #e2e1e6;
        border-bottom: none;
        background-color: #fff;
        color: #676767;

        &:hover {
            cursor: pointer;
        }
    }

    .active {
        background-color: #e2e1e6;
        color: #000b72;
        font-weight: 600;
    }
}

#result_tab_content {
    margin: 0 auto 0 auto;
    background-color: #e2e1e6;
    border-radius: 10px;
}
/* 탭 컨테이너 end */

#result_record_wrap {
    padding: 30px 20px;

    table {
        table-layout: fixed;
    }

    .batter_title{
        margin-bottom: 15px;
        font-size: 18px;
        font-weight: 500;
    }

    .pitcher_title{
        margin-bottom: 15px;
        margin-top: 30px;
        font-size: 18px;
        font-weight: 500;
    }

    .win_div {
        color: #ce0d29;
    }

    .lose_div {
        color: #2a4bac;
    }
    
    .save_div {
        color: #666;
    }

    .hold_div {
        color: #666;
    }

    .none_div {
        color: #000;
    }
}

#we_chart_container {
    padding: 20px;

    .we_chart_title {
        padding: 15px 0px;
        font-size: 18px;
        font-weight: 500;
    }

    .we_chart_content {
        background-color: #fff;
    }
}

.wpa_stat_container {
    width: 50%;
    box-sizing: border-box;
    display: inline-block;
    padding: 0px 15px 30px 15px;
}

.wpa_table_wrap {
    display: flex;
}

.wpa_title {
    font-weight: 500;
    font-size: 18px;
}

.wpa_stat_table {
    width: 50%;
    margin: 5px;
}