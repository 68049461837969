#playerListContainer {
    background-position-x: center;
    background-color: #1e1e1e;
    background-repeat: no-repeat;
    height: auto;
    min-height: calc(100vh - 200px);
    display: flex;
    justify-content: center;
    align-items: center;
}

#playerListTitle {
    // color: #eee;
    font-size: 35px;
    font-style: italic;
    margin-left: 20px;
    margin-top: 10px;
}

#playerListWrap {
    max-width: 1200px;
    min-height: 220px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap : wrap;
    justify-content: center;
    max-height: calc(var(--vh, 1vh) * 68);
    overflow-y: auto;
}

#playerListWrap.overflowItem {
    justify-content: start;
}

.playerCard {
    font-weight: 700;
    font-size: 22px;
    padding: 0 5px 5px 5px;
    background-color: #fff;
    border: 3px solid #888;
    border-top-right-radius: 15px;
    // border-bottom-left-radius: 15px;
    cursor: pointer;
    margin: 10px;
    overflow: hidden;
    height: 186px;
    width: 256px;
}

.playerCard:hover {
    background-color: #ffd5ad;
}

.playerCard .basicInfo {
    height: 65px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.playerCard .playerNameWrap {
    float: left;
    margin-left: 10px;
}

.playerCard .playerNameWrap div {
    display: inline;
}

.playerCard .playerName {
    color: #000;
    font-size: 24px;
    font-weight: 700;
    line-height: 63px;
}

.playerCard .playerName.englishName {
    font-size: 16px;
    line-height: 20px;
}

.playerCard .playerRetireReason {
    color: #4b4b4b;
    font-size: 18px;
    font-weight: 700;
    line-height: 63px;
}

.playerCard .teamLogo {
    float: right;
}

.playerCard .teamLogo .teamImg {
    width: 81px;
    height: 59px;
}

.playerCard .teamLogo .teamName {
    color: #4b4b4b;
    font-size: 18px;
    font-weight: 700;
    line-height: 63px;
    margin-right: 10px;
}

.playerCard .teamLogo .teamName.englishName {
    font-size: 16px;
    line-height: 20px;
}

.playerCard .detailInfo {
    border-top: 1px solid #999;
    padding-top: 9px;
    color: #4b4b4b;
    font-size: 16px;
    display: grid;
	grid-template-columns: 1fr 2fr;
    width: auto;
}

.playerCard .detailInfo .playerImg img {
    width: 80px;
    border-radius: 5px;
}

.playerCard .detailInfo .playerInfo {
    margin-left: 15px;
    margin-top: 5px;
}

.playerCard .detailInfo .playerInfo .playerBtnWrap {
    float: right;
}

.playerCard .detailInfo .playerInfo .playerBtnWrap button {
    float: left;
    font-size: 14px;
    border: 0;
    border-radius: 7px;
    background-color: #555;
    color: white;
    line-height: 17px;
    margin-right: 3px;
    padding: 5px 9px;
}

.playerCard .detailInfo .playerInfo .playerBtnWrap button:hover {
    background-color: #f37320;
}

.playerCard .position {
    color: #4571e9;
}

@media (max-width: 1200px) {
    .playerCard {
        font-weight: 700;
        font-size: 22px;
        padding: 0 5px 5px 5px;
        background-color: #fff;
        border: 3px solid #888;
        border-top-right-radius: 15px;
        // border-bottom-left-radius: 15px;
        cursor: pointer;
        margin: 10px;
        overflow: hidden;
        height: 140px;
        width: 210px;
    }

    .playerCard .basicInfo {
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .playerCard .detailInfo .playerImg img {
        width: 60px;
        border-radius: 5px;
    }

    #playerListWrap {
        margin-right: 15px;
    }
}