$link_word: #1b4397;

$hiball_border: #20489d;

$color_1001 : #002855;
$color_2001 : #ff6600;
$color_3001 : #A50034;
$color_4001 : #1D467D;
$color_5001 : #ce0e2d;
$color_6001 : #ea0029;
$color_7001 : #820024;
$color_8001 : #131230;
$color_9001 : #074CA1;
$color_10001 : #000000;