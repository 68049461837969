#record_title_wrap {
    background-color: #fff;

    #record_title {
        padding: 10px;
        font-size: 18px;
    }
}

#record_tab_container {
    // background-color: #fff;
    display: flex;
    border-bottom: 1px solid #e2e1e6;
}

#record_tab_area {
    width: 20%;
    height: 55px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
	column-gap: 1px;
    // background-color: white;

    .btn {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 500;
        // background-color: #fff;
        color: #676767;

        &:hover {
            cursor: pointer;
        }
    }

    .active {
        color: #000b72;
        font-weight: 600;

        &::after {
            position: absolute;
            bottom: -1px;
            left: 5px;
            right: 5px;
            height: 4px;
            background-color: #000b72;
            content: '';    
        }
    }
}

#record_main_container {
    // padding: 10px 0px;
    border-radius: 10px;
}

#record_filter_container {
    height: 30px;
    text-align: center;
    border: 1px solid black;
    margin: 10px;
    cursor: pointer;
}

#record_sub_tab_container {
    background-color: #fff;
    padding-top: 5px;
    padding-left: 1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#record_sub_tab_area {
    width: 60%;
    height: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    column-gap: 1px;
    // background-color: #fff;
    border-radius: 10px;

    .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: 500;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-top: solid 1px #e2e1e6;
        border-left: solid 1px #e2e1e6;
        border-right: solid 1px #e2e1e6;
        border-bottom: none;
        background-color: #e2e1e6;
        color: #676767;

        &:hover {
            cursor: pointer;
        }
    }

    .active {
        color: #000b72;
        background-color: #fff;
        font-weight: 600;
    }
}

#record_sub_container {
    background-color: #fff;
    padding: 0px;
    margin-bottom: 20px;

    .rdg-header-row {
        background-color: #2a4bac;
        color: white;
        font-weight: 500;
    }
}

.record_table_height {
    height: 750px;

    div {
        text-align: center;
        font-size: 12px;
    }
}

.record_team_table_height {
    height: auto;

    div {
        text-align: center;
        font-size: 12px;
    }
}