#ranking_tab_container {
    // background-color: #fff;
    display: flex;
    border-bottom: 1px solid #e2e1e6;
}

#ranking_tab_area {
    width: 20%;
    height: 55px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
	column-gap: 1px;
    // background-color: white;    

    .btn {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 500;
        // background-color: #fff;
        color: #676767;

        &:hover {
            cursor: pointer;
        }
    }

    .active {
        color: #000b72;
        font-weight: 600;

        &::after {
            position: absolute;
            bottom: -1px;
            left: 5px;
            right: 5px;
            height: 4px;
            background-color: #000b72;
            content: '';    
        }
    }
}

#ranking_detail_container {
    // background-color: #e2e1e6;
    padding: 10px;
    border-radius: 10px;

    .hiball_table {
        tr {
            td {
                background-color: #fff;
            }
        }
    }

    .ranking_item {
        flex:1 1 0;
    }

    .ranking_item:nth-child(2) {
        flex: 2 1 0;
        padding-left: 10px;
    }

    .select_title {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .title {
            margin-left: 10px;
            font-size:20px;
            font-weight: 500;
        }
    }
}

.result_btn {
    display: inline-block;
    background-color: #1a85a9;
    color: white;
    height: 20px;
    border-radius: 12.5px;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    width: 65px;

    &:hover {
        cursor: pointer;
    }
}