/* noto-sans-kr-300 - latin_korean */
@font-face {
     font-family: 'Noto Sans KR';
     font-style: normal;
     font-weight: 300;
     src: url('../font/noto-sans-kr-v27-latin_korean/noto-sans-kr-v27-latin_korean-300.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('../font/noto-sans-kr-v27-latin_korean/noto-sans-kr-v27-latin_korean-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../font/noto-sans-kr-v27-latin_korean/noto-sans-kr-v27-latin_korean-300.woff2') format('woff2'), /* Super Modern Browsers */
          url('../font/noto-sans-kr-v27-latin_korean/noto-sans-kr-v27-latin_korean-300.woff') format('woff'), /* Modern Browsers */
          /* url('../font/noto-sans-kr-v27-latin_korean/noto-sans-kr-v27-latin_korean-300.ttf') format('truetype'), Safari, Android, iOS */
          url('../font/noto-sans-kr-v27-latin_korean/noto-sans-kr-v27-latin_korean-300.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-regular - latin_korean */
@font-face {
     font-family: 'Noto Sans KR';
     font-style: normal;
     font-weight: 400;
     src: url('../font/noto-sans-kr-v27-latin_korean/noto-sans-kr-v27-latin_korean-regular.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('../font/noto-sans-kr-v27-latin_korean/noto-sans-kr-v27-latin_korean-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../font/noto-sans-kr-v27-latin_korean/noto-sans-kr-v27-latin_korean-regular.woff2') format('woff2'), /* Super Modern Browsers */
          url('../font/noto-sans-kr-v27-latin_korean/noto-sans-kr-v27-latin_korean-regular.woff') format('woff'), /* Modern Browsers */
          /* url('../font/noto-sans-kr-v27-latin_korean/noto-sans-kr-v27-latin_korean-regular.ttf') format('truetype'), Safari, Android, iOS */
          url('../font/noto-sans-kr-v27-latin_korean/noto-sans-kr-v27-latin_korean-regular.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-500 - latin_korean */
@font-face {
     font-family: 'Noto Sans KR';
     font-style: normal;
     font-weight: 500;
     src: url('../font/noto-sans-kr-v27-latin_korean/noto-sans-kr-v27-latin_korean-500.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('../font/noto-sans-kr-v27-latin_korean/noto-sans-kr-v27-latin_korean-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../font/noto-sans-kr-v27-latin_korean/noto-sans-kr-v27-latin_korean-500.woff2') format('woff2'), /* Super Modern Browsers */
          url('../font/noto-sans-kr-v27-latin_korean/noto-sans-kr-v27-latin_korean-500.woff') format('woff'), /* Modern Browsers */
          /* url('../font/noto-sans-kr-v27-latin_korean/noto-sans-kr-v27-latin_korean-500.ttf') format('truetype'), Safari, Android, iOS */
          url('../font/noto-sans-kr-v27-latin_korean/noto-sans-kr-v27-latin_korean-500.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-700 - latin_korean */
@font-face {
     font-family: 'Noto Sans KR';
     font-style: normal;
     font-weight: 700;
     src: url('../font/noto-sans-kr-v27-latin_korean/noto-sans-kr-v27-latin_korean-700.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('../font/noto-sans-kr-v27-latin_korean/noto-sans-kr-v27-latin_korean-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../font/noto-sans-kr-v27-latin_korean/noto-sans-kr-v27-latin_korean-700.woff2') format('woff2'), /* Super Modern Browsers */
          url('../font/noto-sans-kr-v27-latin_korean/noto-sans-kr-v27-latin_korean-700.woff') format('woff'), /* Modern Browsers */
          /* url('../font/noto-sans-kr-v27-latin_korean/noto-sans-kr-v27-latin_korean-700.ttf') format('truetype'), Safari, Android, iOS */
          url('../font/noto-sans-kr-v27-latin_korean/noto-sans-kr-v27-latin_korean-700.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}


/* roboto-300 - latin */
@font-face {
     font-family: 'Roboto';
     font-style: normal;
     font-weight: 300;
     src: url('../font/roboto-v30-latin/roboto-v30-latin-300.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('../font/roboto-v30-latin/roboto-v30-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../font/roboto-v30-latin/roboto-v30-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
          url('../font/roboto-v30-latin/roboto-v30-latin-300.woff') format('woff'), /* Modern Browsers */
          url('../font/roboto-v30-latin/roboto-v30-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../font/roboto-v30-latin/roboto-v30-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
     unicode-range: U+0030-0039, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A, U+0061-007A;
}
/* roboto-300italic - latin */
@font-face {
     font-family: 'Roboto';
     font-style: italic;
     font-weight: 300;
     src: url('../font/roboto-v30-latin/roboto-v30-latin-300italic.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('../font/roboto-v30-latin/roboto-v30-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../font/roboto-v30-latin/roboto-v30-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
          url('../font/roboto-v30-latin/roboto-v30-latin-300italic.woff') format('woff'), /* Modern Browsers */
          url('../font/roboto-v30-latin/roboto-v30-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../font/roboto-v30-latin/roboto-v30-latin-300italic.svg#Roboto') format('svg'); /* Legacy iOS */
     unicode-range: U+0030-0039, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A, U+0061-007A;
}
/* roboto-regular - latin */
@font-face {
     font-family: 'Roboto';
     font-style: normal;
     font-weight: 400;
     src: url('../font/roboto-v30-latin/roboto-v30-latin-regular.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('../font/roboto-v30-latin/roboto-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../font/roboto-v30-latin/roboto-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
          url('../font/roboto-v30-latin/roboto-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
          url('../font/roboto-v30-latin/roboto-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../font/roboto-v30-latin/roboto-v30-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
     unicode-range: U+0030-0039, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A, U+0061-007A;
}
/* roboto-italic - latin */
@font-face {
     font-family: 'Roboto';
     font-style: italic;
     font-weight: 400;
     src: url('../font/roboto-v30-latin/roboto-v30-latin-italic.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('../font/roboto-v30-latin/roboto-v30-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../font/roboto-v30-latin/roboto-v30-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
          url('../font/roboto-v30-latin/roboto-v30-latin-italic.woff') format('woff'), /* Modern Browsers */
          url('../font/roboto-v30-latin/roboto-v30-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../font/roboto-v30-latin/roboto-v30-latin-italic.svg#Roboto') format('svg'); /* Legacy iOS */
     unicode-range: U+0030-0039, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A, U+0061-007A;
}
/* roboto-500 - latin */
@font-face {
     font-family: 'Roboto';
     font-style: normal;
     font-weight: 500;
     src: url('../font/roboto-v30-latin/roboto-v30-latin-500.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('../font/roboto-v30-latin/roboto-v30-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../font/roboto-v30-latin/roboto-v30-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
          url('../font/roboto-v30-latin/roboto-v30-latin-500.woff') format('woff'), /* Modern Browsers */
          url('../font/roboto-v30-latin/roboto-v30-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../font/roboto-v30-latin/roboto-v30-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
     unicode-range: U+0030-0039, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A, U+0061-007A;
}
/* roboto-500italic - latin */
@font-face {
     font-family: 'Roboto';
     font-style: italic;
     font-weight: 500;
     src: url('../font/roboto-v30-latin/roboto-v30-latin-500italic.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('../font/roboto-v30-latin/roboto-v30-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../font/roboto-v30-latin/roboto-v30-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
          url('../font/roboto-v30-latin/roboto-v30-latin-500italic.woff') format('woff'), /* Modern Browsers */
          url('../font/roboto-v30-latin/roboto-v30-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../font/roboto-v30-latin/roboto-v30-latin-500italic.svg#Roboto') format('svg'); /* Legacy iOS */
     unicode-range: U+0030-0039, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A, U+0061-007A;
}
/* roboto-700 - latin */
@font-face {
     font-family: 'Roboto';
     font-style: normal;
     font-weight: 700;
     src: url('../font/roboto-v30-latin/roboto-v30-latin-700.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('../font/roboto-v30-latin/roboto-v30-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../font/roboto-v30-latin/roboto-v30-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
          url('../font/roboto-v30-latin/roboto-v30-latin-700.woff') format('woff'), /* Modern Browsers */
          url('../font/roboto-v30-latin/roboto-v30-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../font/roboto-v30-latin/roboto-v30-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
     unicode-range: U+0030-0039, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A, U+0061-007A;
}
/* roboto-700italic - latin */
@font-face {
     font-family: 'Roboto';
     font-style: italic;
     font-weight: 700;
     src: url('../font/roboto-v30-latin/roboto-v30-latin-700italic.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('../font/roboto-v30-latin/roboto-v30-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../font/roboto-v30-latin/roboto-v30-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
          url('../font/roboto-v30-latin/roboto-v30-latin-700italic.woff') format('woff'), /* Modern Browsers */
          url('../font/roboto-v30-latin/roboto-v30-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../font/roboto-v30-latin/roboto-v30-latin-700italic.svg#Roboto') format('svg'); /* Legacy iOS */
     unicode-range: U+0030-0039, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A, U+0061-007A;
}