@import 'color.scss';

/* 날짜네비게이터 start */
#date_navigator_container {
    /* margin-top: -86px; */
    // background-color: white;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;

    .arrow_prev,
    .arrow_next {
        cursor: pointer;
    }

    .text {
        color: #18348b;
        font-size: 30px;
        font-weight: 300;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 15px 0 15px;

        .month,
        .day {
            font-weight: 700;
            margin-right: 5px;
        }

        .day {
            margin-left: 10px;
        }
    }
}
/* 날짜네비게이터 end */

/* 탭 컨테이너 start */
#tab_btn_area {
    margin: 0 auto 0 auto;
    width: 93%;
    height: 44px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	column-gap: 1px;
    background-color: white;

    .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 500;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-top: solid 1px #e2e1e6;
        border-left: solid 1px #e2e1e6;
        border-right: solid 1px #e2e1e6;
        border-bottom: none;
        background-color: #fff;
        color: #676767;

        &:hover {
            cursor: pointer;
        }
    }

    .active {
        border: 1px solid #000b72;
        border-width: 1px 1px 0px 1px;
        // background-color: #e2e1e6;
        color: #000b72;
        font-weight: 600;
    }
}
/* 탭 컨테이너 end */

/* 경기일정 컨테이너 start */
#game_calendar_container {
    margin: 0 auto 0 auto;
    width: 1170px;
    // background-color: #e2e1e6;
    padding: 30px 20px 30px 20px;
    // border-radius: 10px;
    border: 1px solid $hiball_border;

    #active_team_record_wrap {
        text-align: right;
    }

    .half_div {
        width: 50%
    }

    #sort_btn_wrap {
        display: flex;
        gap: 5px;

        .sort_btn {
            background-color: #eee;
            color: #666;
            height: 25px;
            border-radius: 12.5px;
            font-size: 12px;
            font-weight: 400;
            line-height: 25px;
            padding: 0 10px 0 10px;

            &:hover {
                cursor: pointer;
            }
        }

        .active {
            background-color: #2a4bac;
            color: white;
        }
    }
}

#game_calendar_table {
    margin-top: 15px;
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    table-layout: fixed;

    thead {
        tr {
            &:first-child {
                background-color: #1b477d;
                color: white;
            }
        }
    }

    tbody {
        &:nth-child(odd) {
            tr {
                background-color: #f0f0f0;

                &:first-child {
                    td {
                        &:first-child {
                            background: #475a85;
                            color: white;
                            border-bottom: 0;
                            font-size: 16px;
                            width: 103px;
                            pointer-events: none;
                        }
                    }
                }
            }
        }

        &:nth-child(even) {
            tr {
                background-color: #fff;

                &:first-child {
                    td {
                        &:first-child {
                            background-color: #0f2760;
                            color: white;
                            border-bottom: 0;
                            font-size: 16px;
                            width: 103px;
                            pointer-events: none;
                        }
                    }
                }
            }
        }

        tr {
            &:hover {
                background-color: #f0f2ff;
            }
        }
    }

    tr {
        td {
            border-bottom: 1px solid #ddd;
        }
    }

    th {
        font-size: 14px;
        font-weight: 500;
        height: 30px;
    }

    td {
        color: #666;
        font-size: 14px;
        font-weight: 400;
        height: 30px;
        min-width: 80px;

        &.lose_td {
            opacity: 0.5;
        }

        img {
            width: 30px;
            height: 20px;
        }

        .win_div {
            display: inline-block;
            width: 18px;
            height: 18px;
            background-color: #ce0d29;
            color: white;
            border-radius: 9px;
            line-height: 18px;
            margin-right: 1px;
        }
    
        .lose_div {
            display: inline-block;
            width: 18px;
            height: 18px;
            background-color: #2a4bac;
            color: white;
            border-radius: 9px;
            line-height: 18px;
            margin-right: 1px;
        }
        
        .save_div {
            display: inline-block;
            width: 18px;
            height: 18px;
            background-color: #666;
            color: white;
            border-radius: 9px;
            line-height: 18px;
            margin-right: 1px;
        }
    }

    span {
        display: inline-block;
        line-height: 14px;
        vertical-align: middle;
    }

    .away_name,
    .home_name {
        width: 40px;
    }

    .away_score,
    .home_score {
        width: 23px;
        color: black;
        font-weight: 500;
    }

    .vs {
        color: black;
        font-weight: 500;
    }

    .higher {
        color: #1a85a9;
    }

    .away_logo,
    .home_logo {
        img {
            height: 23px;
        }
    }

    .result_btn {
        display: inline-block;
        background-color: #1a85a9;
        color: white;
        height: 25px;
        border-radius: 12.5px;
        font-size: 12px;
        font-weight: 400;
        line-height: 25px;
        width: 65px;

        &:hover {
            cursor: pointer;
        }
    }
}
/* 경기일정 컨테이너 end */