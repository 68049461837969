#loginBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 500px;
    background-color: #e2e1e6;
    margin: 20px auto 0 auto;
    padding: 45px 20px 35px 20px;
    border-radius: 10px;

    .titleWrap {
        text-align: center;
        margin-bottom: 20px;

        span {
            &:nth-child(1) {
                color: #0371ed;
                font-size: 35px;
                font-weight: 300;
            }

            &:nth-child(2) {
                margin-left: 10px;
                color: #012461;
                font-size: 35px;
                font-weight: 700;
            }
        }
    }

    .inputWrap {
        margin-bottom: 10px;

        input {
            background-color: #fff;
            width: 400px;
            height: 45px;
            border: none;
            font-size: 20px;
            padding: 0 15px;
            border-radius: 7px;
            border: solid 2px #e2e1e6;

            &.warning {
                border: solid 2px red;
            }
        }
    }

    #warningMessage {
        display: block;
        text-align: left;
        color: red;
        margin-left: 15px;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
    }

    .fncWrap {
        button {
            cursor: pointer;
            font-weight: 600;
            border: none;
            background-color: transparent;
            color: #666;
            padding: 0;

            &:not(:first-child)::before {
                content: "";
                display: inline-block;
                width: 1px;
                height: 11px;
                background-color: #666;
                margin: 0 13px;
            }
        }
    }

    .buttonWrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 430px;
            height: 55px;
            line-height: 55px;
            font-size: 20px;
            font-weight: 700;
            border: 1px solid #777;
            border-radius: 5px;
            cursor: pointer;

            &#loginBtn {
                background-color: #012461;
                color: #fff;
            }

            &#googleBtn {
                background-color: #fff;
                color: #777;
                font-size: 16px;
                padding-right: 20px;
            }

            &#kakaoBtn {
                background-color: #fee500;
                color: #000;
                font-size: 16px;
                padding-right: 20px;
            }

            &#naverBtn {
                background-color: #03c75a;
                color: #fff;
                font-size: 16px;
                padding-right: 20px;
            }

            .icon {
                display: inline-block;
                width: 40px;
                height: 40px;
                background-repeat: no-repeat;

                &.google {
                    background-image: url('../image/sub/login/google.png');
                    background-size: 40px;
                }

                &.kakao {
                    background-image: url('../image/sub/login/kakao.png');
                    background-size: 40px;
                }

                &.naver {
                    background-image: url('../image/sub/login/naver.png');
                    background-size: 40px;
                }
            }
        }
    }
}

#OAuthLoader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
        margin-bottom: 50px;
    }
}