@import 'color.scss';

/* 공통 start */
html, body {
    margin: 0;
    padding: 0;
    border: 0;
}

body {
    min-width: 1200px;
    width: 100%;
    font-family: 'Roboto', 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    color: #4e4e4e;
    overflow-x: hidden;
}

.container {
    max-width: 1200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.color_darkgray {
    color: #3a3a3a !important;
}

.color_red {
    color: #ce0d29 !important;
}

.color_blue {
    color: #20489d !important;
}

.color_skyblue {
    color: #2683a5 !important;
}

.color_orange {
    color: #eb7724 !important;
}
/* 공통 end */

/* 팀 컬러 start */
.font_color_1001 {
    color: $color_1001;
}

.font_color_2001 {
    color: $color_2001;
}

.font_color_3001 {
    color: $color_3001;
}

.font_color_4001 {
    color: $color_4001;
}

.font_color_5001 {
    color: $color_5001;
}

.font_color_6001 {
    color: $color_6001;
}

.font_color_7001 {
    color: $color_7001;
}

.font_color_8001 {
    color: $color_8001;
}

.font_color_9001 {
    color: $color_9001;
}

.font_color_10001 {
    color: $color_10001;
}

.bg_color_1001 {
    background-color: $color_1001;
    color: white;
}

.bg_color_2001 {
    background-color: $color_2001;
    color: white;
}

.bg_color_3001 {
    background-color: $color_3001;
    color: white;
}

.bg_color_4001 {
    background-color: $color_4001;
    color: white;
}

.bg_color_5001 {
    background-color: $color_5001;
    color: white;
}

.bg_color_6001 {
    background-color: $color_6001;
    color: white;
}

.bg_color_7001 {
    background-color: $color_7001;
    color: white;
}

.bg_color_8001 {
    background-color: $color_8001;
    color: white;
}

.bg_color_9001 {
    background-color: $color_9001;
    color: white;
}

.bg_color_10001 {
    background-color: $color_10001;
    color: white;
}

.team1001-border {
    border-color: $color_1001 !important;
}
.team2001-border {
    border-color: $color_2001 !important;
}
.team3001-border {
    border-color: $color_3001 !important;
}
.team4001-border {
    border-color: $color_4001 !important;
}
.team5001-border {
    border-color: $color_5001 !important;
}
.team6001-border {
    border-color: $color_6001 !important;
}
.team7001-border {
    border-color: $color_7001 !important;
}
.team8001-border {
    border-color: $color_8001 !important;
}
.team9001-border {
    border-color: $color_9001 !important;
}
.team10001-border {
    border-color: $color_10001 !important;
}
/* 팀 컬러 end */

/* 헤더 영역 start */
#header {
    // background-color: #20489d;
    background-color: #fff;
    border-bottom: 4px solid #20489d;
}

#header_border {
    height: 30px;
    // background-image: url('../mage/common/bg_top.png');
    background-color: #EAEAEA;
}

#search_bar {
    // width: 21%;
    height: 62px;
    display:flex;
    align-items: center;
    justify-content: center;
}

#auth_menu {
    height: 30px;
    width: 14%;
    float: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .user_wrap {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        // gap: 5px 10px;
        line-height: 16px;

        span {
            width: 100%;
            font-size: 14px;
            color: #20489d;
            font-weight: 700;
            text-align: right;
        }

        button {
            cursor: pointer;
            padding: 0 12px;
            height: 28px;

            &#loginBtn,
            &#joinBtn {
                font-size: 12px;
                background-color: transparent;
                color: #012461;
                border: none;
                // border-radius: 5px;
                font-weight: 600;
            }

            &#logoutBtn,
            &#profileBtn {
                font-size: 12px;
                background-color: transparent;
                color: #012461;
                border: none;
                // border-radius: 5px;
                font-weight: 600;
            }
        }
    }
}

#top_menu {
    width: 100%;
    height: 62px;
    line-height: 62px;

    .gap {
        height: 62px;
        width: 8%;
        float: left;
    }
}

#logo_wrap {
    display: flex;
    align-items: center;
    height: 62px;
    width: 13%;
    float: left;

    img {
        max-height: 62px;
        max-width: 100%;
        vertical-align: middle;
    }
}

#navigation {
    display: flex;
    justify-content: space-between;
    width: 58%;
    float: left;
    font-size: 18px;
    font-weight: 500;

    a {
        text-decoration: none;
        // color: #90b4ff;
        color: #000;
        cursor: pointer;

        &:hover {
            // color: #fff;
            color: $hiball_border;
        }

        &.active {
            // color: #fff;
            color: $hiball_border;
        }
    }
}
/* 헤더 영역 end */

/* 공통 start */
.wrapper{
    display: flex;
    flex-direction: column;
}

#main_content {
    // padding: 30px 0;
    height: auto;
    min-height: calc(100vh - 207px);

    &.login {
        background-size: unset;
    }

    .section_div {
        margin: 35px auto 25px auto;
        text-align: center;

        .section_title {
            font-size: 20px;
            font-weight: 500;
            line-height: 30px;
            background-color: #fff;
            padding: 0 15px;
        }

        .section_line {
            border-top: 2px solid #20489d;
            margin: -15px auto 0px auto;
            width: 60%;
        }
    }
}
/* 공통 end */

/* 푸터 영역 start */
#footer {
    background-color: #848484;
    height: 140px;
}

#footer_menu {
    color: white;
    height: 52px;
    line-height: 52px;
    text-align: center;
    border-bottom: 1px solid #bbb;
    font-size: 14px;
    font-weight: 500;

    span {
        margin-left: 30px;

        &:hover {
            color: #214eaf;
            cursor: pointer;
        }

        &:first-child {
            margin-left : 0;
        }
    }
}

#footer_comment {
    text-align: center;
    font-size: 14px;
    margin-top: 20px;
}

#footer_copyright {
    font-size: 12px;
}
/* 푸터 영역 end */

.flex_div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;

    img {
        height: 18px;
        width: 27px;
    }
}

.flex_div_reverse {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;

    img {
        height: 18px;
        width: 27px;
    }
}

span.link {
    color: #1b4397;
    cursor: pointer;
}

a.link {
    color: #1b4397;
    cursor: pointer;
    text-decoration-line: none;
}

@media (max-width: 1200px) {
    body {
        width: 100vw;
        min-width: 0;
    }

    .container {
        width: 100vw;
        margin-left: 0;
        margin-right: 0;
    }

    .wrapper{
        display: flex;
        flex-direction: column;
        width: 100vw;
    }
}