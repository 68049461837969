.info_wrap {
    width: 33%;
    background-color: #fff;
    border-radius: 10px;
}

#player_info_container {
    margin-top: 10px;
    // background-color: #fff;
    height: 370px;
    display: flex;
}

#player_info_container .body {
    margin:15px;
}

#player_info_container .body .top {
    display: flex;
}

#player_info_container .backnum_wrap {
    position: relative;
    height:118px;
}

#player_info_container .backnum_wrap img {
    width: 110px;
}

#player_info_container .backnum_wrap .backnum_text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    color: white;
    font-size: 50px;
}

#player_info_container .profile {
    margin-left: 15px;
    min-width: 161px;
}

#player_info_container .profile_title {
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 12px;
}

#player_info_container .profile_content {
    padding-top: 12px;
}

#player_info_container .profile_content td {
    color: #555;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;

    .playerListLink {
        cursor: pointer;
        text-decoration: underline;
        color: blue;
    }
}

#player_info_container .profile_content td:first-child {
    color: #2683a5;
}

#player_tab_btn_area {
    margin: 0 auto 0 auto;
    width: 50%;
    height: 44px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	column-gap: 1px;
    background-color: white;
}

#player_tab_btn_area .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-top: solid 1px #e2e1e6;
    border-left: solid 1px #e2e1e6;
    border-right: solid 1px #e2e1e6;
    border-bottom: none;
    background-color: #fff;
    color: #676767;
}

#player_tab_btn_area .active {
    background-color: #e2e1e6;
    color: #000b72;
    font-weight: 600;
}

#player_tab_btn_area .btn:hover {
    cursor: pointer;
}

#player_tab_content {
    margin: 0 auto 0 auto;
    // width: 1170px;
    background-color: #e2e1e6;
    // box-shadow: 0 0 5px 1px #ccc;
    border-radius: 10px;
}

#player_basic_container,
#situation_basic_container,
#team_basic_container,
#team_depth_container,
#position_basic_container {
    padding: 20px;
    background-color: #e2e1e6;
    border-radius: 10px;
}

#player_tab_content .table_title,
#situation_basic_container .table_title,
#team_basic_container .table_title {
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 500;
}

#player_tab_content .table_body,
#situation_basic_container .table_body,
#team_basic_container .table_body {
    margin-bottom: 20px;
}

#team_info_container {
    background-color: #e2e1e6;
    height: 700px;
    padding: 8px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .team_info_row {
        display: flex;
        justify-content: space-between;

        .info_wrap {
            width: 33%;
            background-color: #fff;
            border-radius: 10px;
            height: 345px;
        }
    }

    .body {
        margin: 15px;

        .top { display: flex;}
    }

    .backnum_wrap {
        position: relative;
        height: 138px;

        img { width: 110px;}
    }

    .profile {
        margin-left: 15px;
        min-width: 161px;
    }

    .profile_title {
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        text-align: center;
        border-bottom: 1px solid #ddd;
        padding-bottom: 12px;
    }

    .profile_content {
        padding-top: 12px;

        td { color: #555; font-size: 14px; font-weight: 400; line-height: 14px; }
        td:first-child { color: #2683a5; }
    }
}

#team_tab_btn_area {
    margin: 0 auto 0 auto;
    width: 50%;
    height: 44px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	column-gap: 1px;
    background-color: white;
}

#team_tab_btn_area .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-top: solid 1px #e2e1e6;
    border-left: solid 1px #e2e1e6;
    border-right: solid 1px #e2e1e6;
    border-bottom: none;
    background-color: #fff;
    color: #676767;
}

#team_tab_btn_area .active {
    background-color: #e2e1e6;
    color: #000b72;
    font-weight: 600;
}

#team_tab_btn_area .btn:hover {
    cursor: pointer;
}

#team_depth_container {
    display: flex;
    justify-content: center;
}

#team_depth_container .head {
    margin-top: 20px;
    margin-left: 20px;
}

#team_depth_container .head .team_icon {
    width: 78px;
    height: 30px;
    font-size: 14px;
    font-weight: 400;
    border-radius: 15px;
    line-height: 30px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

#team_depth_container .head .team_icon img {
    width: 18px;
    height: 18px;
    margin-right: 3px;
}

#team_depth_container .head .team_logo_wrap {
    display: flex;
    justify-content: center;
}

#team_depth_container .head .team_logo {
    width: 112px;
    height: 82px;
}

#team_depth_container .body {
    padding-top: 70px;
    padding-bottom: 100px;
    margin-left: 50px;
    margin-right: 50px;
    position: relative;
}

#team_depth_container .stadium_bg img {
    width: 550px;
}

#team_depth_container .depth_list {
    width: 103px;
    height: 100px;
    border: 1px solid #a3b7f3;
    background-color: rgba(255, 255, 255, 0.5);
}

#team_depth_container .depth_row1, #team_depth_container .depth_row2,
#team_depth_container .depth_row3, #team_depth_container .depth_row4 {
    display: flex;
    position: absolute;
}

#team_depth_container .depth_row1 {
    top: 35px;
}

#team_depth_container .depth_row2 {
    top: 155px;
}

#team_depth_container .depth_row3 {
    top: 274px;
}

#team_depth_container .depth_row4 {
    top: 450px;
}

#team_depth_container .depth_list .position_name {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
}

#team_depth_container .depth_list .depth_content {
    padding: 10px;
}

#team_depth_container .depth_list .depth_content td {
    font-size: 13px;
    font-weight: 400;
    text-align: right;
    line-height: 14px;
    color: #666;
}

#team_depth_container .depth_list .depth_content tr:first-child td {
    color: black;
}

#team_depth_container .depth_list .depth_content tr td:first-child {
    text-align: left;
    width: 60px;
}

#team_depth_container #team_lf, #team_depth_container #team_lf {
    margin-left: 40px;
}

#team_depth_container #team_lf, #team_depth_container #team_lf,
#team_depth_container #team_rf, #team_depth_container #team_rf {
    margin-top: 35px;
}

#team_depth_container #team_cf, #team_depth_container #team_cf,
#team_depth_container #team_rf, #team_depth_container #team_rf {
    margin-left: 79px;
}

#team_depth_container #team_ss, #team_depth_container #team_ss {
    margin-left: 150px;
}

#team_depth_container #team_2b, #team_depth_container #team_2b {
    margin-left: 43px;
}

#team_depth_container #team_rp, #team_depth_container #team_rp {
    height: 200px;
}

#team_depth_container #team_sp, #team_depth_container #team_sp {
    height: 137px;
}

#team_depth_container #team_3b, #team_depth_container #team_3b,
#team_depth_container #team_sp, #team_depth_container #team_sp,
#team_depth_container #team_1b, #team_depth_container #team_1b,
#team_depth_container #team_dh, #team_depth_container #team_dh {
    margin-left: 7px;
}

#team_depth_container #team_rp, #team_depth_container #team_rp,
#team_depth_container #team_3b, #team_depth_container #team_3b,
#team_depth_container #team_1b, #team_depth_container #team_1b,
#team_depth_container #team_dh, #team_depth_container #team_dh {
    margin-top: 75px;
}

#team_depth_container #team_c, #team_depth_container #team_c {
    margin-left: 226px;
}

.trackmanArea {
    padding: 5px;
}

.teamTitle {
    font-size: 18px;
    margin-left: 15px;
    // font-weight: bold;
    color: #000;
    display: flex;
    flex-direction: column;
    height: 25px;
    justify-content: center;
}

.trackmanChart {
    display: flex;
    justify-content: space-evenly;
}

.team_small_depth {
    display: flex;
    justify-content: center;

    .depth_body {
        margin: 0px 5px;
        position: relative;
    }

    .stadium_bg img {
        width: 330px;
    }

    .depth_list {
        width: 100px;
        height: 30px;
        border: 1px solid #a3b7f3;
        background-color: rgba(255, 255, 255, 0.5);
    }

    .depth_row1, .depth_row2,
    .depth_row3, .depth_row4 {
        display: flex;
        position: absolute;
        margin-top: 25px;
    }
    
    .depth_row1 {
        top: 0px;
    }
    
    .depth_row2 {
        top: 80px;
    }
    
    .depth_row3 {
        top: 125px;
    }
    
    .depth_row4 {
        top: 165px;
    }

    .depth_list {
        .position_name {
            text-align: center;
            font-size: 14px;
            font-weight: 500;
        }

        .depth_content {
            padding: 6px;

            td {
                font-size: 13px;
                font-weight: 400;
                text-align: right;
                line-height: 14px;
                color: #666;
            }

            tr:first-child td {
                color: black;
            }

            tr td:first-child {
                text-align: left;
                width: 60px;
            }
        }
    }

    .team_lf {margin-left: 6px;}
    .team_lf, .team_rf {margin-top: 35px;}
    .team_rf, .team_cf {margin-left: 6px;}
    .team_ss {margin-left: 38px;}
    .team_2b {margin-left: 50px;}

    .team_3b, .team_sp, .team_1b { margin-left: 6px;}

    .team_c, .team_dh {margin-left: 6px;}
    .team_c { margin-top: 20px;}
    .team_dh { margin-left: 6px; height: 48px;}    
    .team_rp { height: 68px; margin-left: 6px;}
}